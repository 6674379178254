/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unreachable */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-inner-declarations */
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Chip,
	Icon,
	IconButton,
	MenuItem,
	Tooltip
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 } from "uuid";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import lod_ from "lodash";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import FormActions from "redux-react/actions/formAction";
import SettingsActions from "redux-react/actions/settingsActions";
import MDTypography from "components/Basics/MDTypography";
import { createDictionarySkeleton } from "helpers/form";
import { LittleForm } from "..";

const DisplayItem = (props, option) => {
	return (
		<MenuItem {...props}>
			<MDBox>
				<MDTypography variant="h6">{option?.name}</MDTypography>
				<MDTypography variant="body3" fontSize="small">
					{option?.description}
				</MDTypography>
			</MDBox>
		</MenuItem>
	);
};

const Action = ({
	index,
	listOfApis = [],
	actionProps,
	dragHandleProps,
	onClick,
	onChangeConfig,
	actions,
	dictionaries,
	setConfig
}) => {
	let correctAction = actions.find(item => item.code === actionProps.code);
	let correctDictionary = dictionaries[correctAction?.dictionary] ?? {};
	let skeleton = createDictionarySkeleton(correctDictionary, actionProps.config);

	useEffect(() => {
		setConfig(actionProps.uuid, skeleton);
	}, []);

	return (
		<Accordion
			className="customAccordion"
			sx={{ width: "100%", mb: 1 }}
			style={{
				marginBottom: "1%"
			}}
			onClick={e => {
				e.stopPropagation();
			}}
		>
			<AccordionSummary
				expandIcon={
					<IconButton>
						<Icon>expand_more</Icon>
					</IconButton>
				}
				{...dragHandleProps}
			>
				<MDBox
					sx={{ width: "100%" }}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<MDBox display="flex" alignItems="center">
						<MDTypography variant="h6">
							{`${index + 1} - `}
							{correctAction?.name ?? actionProps?.code}
						</MDTypography>
					</MDBox>
					<IconButton
						size="small"
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							onClick(e);
						}}
					>
						<Icon>close</Icon>
					</IconButton>
				</MDBox>
			</AccordionSummary>
			<AccordionDetails style={{ zIndex: 1000 }}>
				<LittleForm
					object={correctDictionary.items ?? {}}
					metadatasSkeleton={skeleton}
					handleChange={(path, value) => {
						onChangeConfig(path, value);
					}}
					listAPIs={listOfApis}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

const InputActions = ({
	label,
	disabled = false,
	value,
	onChange,
	item = {},
	path = "",
	listOfApis = [],
	error,
	...rest
}) => {
	const dispatch = useDispatch();

	/* Storage */
	const [actions, setActions] = useState([]);
	const [dictionaries, setDictionaries] = useState({});
	const [loading, setLoading] = useState(true);

	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const { user } = useSelector(state => state);

	const [options, setOptions] = useState([]);

	let whitelistDynamic = item.whitelistDynamic || {};

	async function loadSuggestions(searchText, collection, searchFields, apiName, action, limit = 20) {
		let querryData = [];
		searchFields.forEach(field => {
			const query = {};
			query[field] = { $regex: searchText, $options: "i" };
			querryData.push(query);
		});

		let attribute = whitelistDynamic?.params?.attribute;

		querryData.push({ [attribute]: { $regex: searchText, $options: "i" } });

		let dataQuerry = JSON.stringify({
			query: {
				$or: querryData
			}
		});

		let APIFind;
		let actionFind;
		if (apiName && action) {
			APIFind = listOfApis.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			return [];
		}

		let account = actionFind?.account ?? false;

		collection = collection || actionFind?.collection;

		// Merge collections is an array of collections name, that will be requested in the same time
		// The result will be merged in the same array
		const mergeCollections = actionFind?.mergeCollections || [];

		let config = {
			method: actionFind?.method ?? "GET",
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${collection}/items?search=${searchText}&page=1&limit=${limit}&account=${account}`,
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			}
		};

		if (config.method === "GET") {
			config.data = dataQuerry;
		} else {
			config.data = {
				filter: actionFind?.filter,
				searchFields,
				attribute,
				mergeCollections,
				limit,
				query: {
					$or: querryData
				}
			};
		}

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});

		return result?.results || [];
	}

	async function loadSuggestionsInit() {
		if (lod_.isEmpty(whitelistDynamic) || lod_.isEmpty(listOfApis)) return;

		let result = await loadSuggestions(
			"",
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields,
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptions(result);
	}

	const onChangeAutocomplete = (reason, newValue, option) => {
		switch (reason) {
			case "clear":
			case "removeOption":
				onChange(newValue);
				break;
			case "selectOption":
				{
					let config = {
						uuid: v4(),
						code: option.code,
						config: {}
					};

					let newValue = [...value, config];

					onChange(newValue);
				}
				break;
			default:
				break;
		}
	};

	const onChangeInput = async e => {
		let result = await loadSuggestions(
			e.target.value,
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields,
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptions(result);
	};

	const getOptionLabel = option => {
		return option.name;
	};

	const setConfig = (uuid, config) => {
		let actionCloned = value.find(v => v.uuid === uuid);
		actionCloned.config = config;
		onChange(value);
	};

	const onChangeConfig = (uuid, path, valueProps) => {
		let actionCloned = value.find(v => v.uuid === uuid);
		lod_.set(actionCloned, `config.${path}`, valueProps);
		onChange(value);
	};

	const getConfigDictionarySuccess = res => {
		let dictionaries = res?.dictionaries ?? {};
		setDictionaries(dictionaries);
		setLoading(false);
	};

	const getConfigDictionary = codes => {
		dispatch(
			SettingsActions.getDictionaries(
				{
					query: {
						codeDictionary: { $in: codes }
					}
				},
				res => {
					getConfigDictionarySuccess(res);
				}
			)
		);
	};

	const loadActions = () => {
		if (!listOfApis || !listOfApis.length) return;
		let apiName = whitelistDynamic.apiName;
		let apiAction = whitelistDynamic.action;
		let correctAPI = listOfApis.find(item => item.name === apiName) || {};
		if (!correctAPI) return;
		let correctAction = correctAPI.config.action[apiAction];
		let collection = correctAction?.collection;
		dispatch(
			FormActions.getItemsFromCollection(
				collection,
				{
					query: {}
				},
				res => {
					let result = res.items;
					setActions(result);
					let dictionariesResult = result.map(item => item.dictionary);
					getConfigDictionary(dictionariesResult);
				}
			)
		);
	};

	useEffect(() => {
		loadSuggestionsInit();
		loadActions();
	}, [whitelistDynamic, listOfApis]);

	if (loading) return null;

	return (
		<MDBox
			component="fieldset"
			style={{
				border: "1px solid #d2d6da"
				// borderRadius: "0.375rem"
			}}
			borderRadius="md"
			p={1}
		>
			<legend
				style={{
					lineHeight: 1.5,
					fontSize: "0.75rem",
					color: "#7b809a",
					fontWeight: 400,
					margin: "4px",
					padding: "0 4px"
				}}
			>
				Actions
			</legend>
			<MDBox>
				<DragDropContext
					onDragEnd={result => {
						const { destination, source } = result;

						if (!destination) return;

						const reorderedValue = Array.from(value);
						const [removed] = reorderedValue.splice(source.index, 1);
						reorderedValue.splice(destination.index, 0, removed);

						onChange(reorderedValue);
					}}
				>
					<Droppable droppableId="dnd-list-actions" direction="vertical">
						{provided => (
							<MDBox {...provided.droppableProps} ref={provided.innerRef}>
								{value.map((action, index) => {
									return (
										<Draggable key={action.uuid} index={index} draggableId={action.uuid}>
											{provided => (
												<div ref={provided.innerRef} {...provided.draggableProps}>
													<Action
														key={index}
														dragHandleProps={provided.dragHandleProps}
														index={index}
														actionProps={action}
														listOfApis={listOfApis}
														onClick={e => {
															let clonedValue = lod_.cloneDeep(value);
															let filteredValue = clonedValue.filter(
																item => item.uuid !== action.uuid
															);
															onChange(filteredValue);
														}}
														onChangeConfig={(path, value) => {
															onChangeConfig(action.uuid, path, value);
														}}
														actions={actions}
														dictionaries={dictionaries}
														setConfig={setConfig}
													/>
													{/* In case we want to display arrow at the bottom of each action to show the process workflow */}
													{/* {index !== value.length - 1 && (
														<MDBox
															display="flex"
															justifyContent="center"
															flexDirection="column"
															alignItems="center"
														>
															<Icon>south</Icon>
														</MDBox>
													)} */}
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</MDBox>
						)}
					</Droppable>
				</DragDropContext>
			</MDBox>
			{/* Search input */}
			<Autocomplete
				sx={{ mt: 1 }}
				disableClearable
				// value={value}
				value={[]}
				multiple
				id="tags-filled"
				options={options}
				freeSolo
				getOptionLabel={getOptionLabel}
				onChange={(event, newValue, reason, { option } = {}) => {
					onChangeAutocomplete(reason, newValue, option);
				}}
				renderOption={(props, option) => {
					return DisplayItem(props, option);
				}}
				renderTags={(tags, getTagProps) => {
					return tags.map((action, index) => {
						return (
							<Action
								key={index}
								action={action}
								whitelistDynamic={whitelistDynamic}
								listOfApis={listOfApis}
								onClick={e => {
									getTagProps({ index }).onDelete(e);
								}}
								onChangeConfig={(path, value) => {
									onChangeConfig(action.uuid, path, value);
								}}
								setConfig={config => {
									setConfig(action.uuid, config);
								}}
							/>
						);
					});
				}}
				renderInput={params => (
					<MDInput
						{...params}
						onChange={onChangeInput}
						// label="Actions"
						placeholder="Ajouter une action ..."
						onKeyDown={event => {
							if (event.key === "Backspace") {
								event.stopPropagation();
							}
						}}
					/>
				)}
			/>
		</MDBox>
	);
};
export default InputActions;
