/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-destructuring */
/**
 * =============================================================================
 *
 * fAIbrik HTML Editor, based on GrapesJS
 *
 * =============================================================================
 */
import "./style.css";

// Dependencies
import { useState, useEffect, useRef } from "react";
import lod_ from "lodash";
import { t } from "i18next";
import juice from "juice";

// Grapesjs dependencies
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import webPlugin from "grapesjs-preset-webpage";
import basicPlugin from "grapesjs-blocks-basic";
import tabs from "grapesjs-tabs";
import fr from "grapesjs/locale/fr";

// Components
import { getSignedUrlS3, uploadToSignedUrl } from "helpers/s3";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";

import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { useSelector } from "react-redux";

import BaseReactComponent from "./components/base-react-component";

// Language
// import fr from "./components/fr";

/**
 * Main component
 * @param {Boolean} simpleEditor - If the editor is simple or not (remove some features if true)
 * @param {String} html - The default HTML content to display
 * @param {Boolean} editMode - If the editor is in edit mode
 * @param {String} id - The id of the answer item (if in edit mode)
 * @param {Boolean} open - If the editor is open
 * @param {Function} onClose - The function to call when the editor is closed
 * @param {Function} onSave - The function to call when the editor is saved
 * @param {Object} variablesDictionary - The dictionary of variables
 * @returns
 */
const HTMLEditor = ({
	simpleEditor = false,
	html = "",
	editMode = false,
	id: answerItemId = null,
	open,
	onClose,
	onSave,
	variablesDictionary
}) => {
	const profile = useSelector(selectCurrentProfile);
	const editorRef = useRef(null);
	/* TODO: Store temporary files, when closing the editor, we need to delete them of S3 */
	const [temporaryFiles, setTemporaryFiles] = useState([]);

	const [variablesDictionaryAnchor, setVariablesDictionaryAnchor] = useState(null);
	const [currentVariableBlock, setCurrentVariableBlock] = useState(null);

	const language = profile?.language?.toLowerCase() || "fr";

	/* Main use effect */
	useEffect(() => {
		/**
		 * Initialize the editor
		 * https://grapesjs.com/docs/getting-started.html#start-from-the-canvas
		 */
		const editor = grapesjs.init({
			// Indicate where to init the editor. You can also pass an HTMLElement
			container: "#gjs",
			// Get the content for the canvas from the element
			fromElement: true,
			// Size of the editor
			height: "100vh",
			width: "auto",
			// Disable the storage manager
			storageManager: false,
			// Plugins
			plugins: simpleEditor
				? [basicPlugin, webPlugin, BaseReactComponent]
				: [basicPlugin, webPlugin, BaseReactComponent, tabs],
			// Plugins options
			pluginsOpts: {
				[webPlugin]: {
					blocks: [
						"column1",
						"column2",
						"column3",
						"column3-7",
						"text",
						"link",
						"image",
						"video",
						"map"
					]
				}
			},
			// Assets
			assetManager: {
				/**
				 * Asset manager configuration =>
				 * https://github.com/GrapesJS/grapesjs/blob/master/src/asset_manager/config/config.ts
				 */
				// Disable default upload
				upload: false,
				// Custom upload
				uploadFile: async (e, add) => {
					// Get the files from input
					const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

					let UPLOADED_FILES = [];

					// Upload each file to S3
					for (let file of files) {
						const { accessURL, signedURL } = await getSignedUrlS3(file);
						await uploadToSignedUrl(signedURL, file);
						setTemporaryFiles([
							...temporaryFiles,
							{
								accessURL,
								signedURL
							}
						]);

						UPLOADED_FILES.push({
							src: accessURL,
							name: file.name,
							type: "image",
							size: file.size
						});
					}

					// Add assets to asset manager
					editor.AssetManager.add(UPLOADED_FILES);

					// Add is available only when user upload files with drag and drop in editor
					if (add) {
						add({
							data: UPLOADED_FILES
						});
					}
				}
			}
		});

		const changeLanguage = async () => {
			try {
				// Dynamically import the translation file
				const translation = await import(`grapesjs/locale/${language}`);

				// Add the translation to the editor
				editor.I18n.addMessages({
					[language]: translation.default
				});
				editor.I18n.setLocale(language);
			} catch (error) {
				// Do nothing
			}
		};

		changeLanguage();

		// Edit editor style, for html tag add 5px padding
		editor.setStyle(
			`
			html {
				padding: 5px;
			}
			`
		);

		// Optionally, override existing block if needed
		editor.BlockManager.get("text").set("content", {
			type: "text",
			content: "Double cliquez pour éditer le texte"
		});

		/**
		 * Define a new component type for "variable"
		 */
		editor.DomComponents.addType("variable", {
			isComponent: el => el.tagName === "SPAN" && el.classList.contains("variable-component"),
			model: {
				defaults: {
					toolbar: [
						{
							attributes: { class: "fa fa-edit", title: t("EDITOR.changeTheVariable") },
							command: editor => {
								let block = editor.getSelected();
								setCurrentVariableBlock(block);
								setVariablesDictionaryAnchor(block.view.el);
							}
						},
						{
							attributes: { class: "fa fa-trash", title: t("EDITOR.delete") },
							command: "tlb-delete"
						}
					],
					traits: [
						{
							type: "text",
							name: "data-value",
							label: "Value",
							editable: false,
							full: true
						}
					],
					tagName: "variable",
					attributes: {
						class: "variable-component",
						contenteditable: "false"
					},
					styles: {
						"background-color": "red",
						"font-family": "Arial",
						"font-size": "14px",
						color: "white",
						padding: "2px 4px"
					},
					editable: false,
					copyable: false,
					removable: true,
					draggable: false
				},
				init() {
					this.on("change:attributes:data-value", this.updateStyle);
					this.updateStyle();
				},
				updateStyle() {
					const hasValue = !!this.getAttributes()["data-value"];
					this.addStyle({
						"background-color": hasValue ? "green" : "red",
						"font-family": "Arial",
						"font-size": "14px",
						color: "white",
						padding: "3px 8px",
						margin: "0 4px",
						"border-radius": "5px"
					});
				}
			},
			view: {
				onRender() {
					const el = this.el;
					el.contentEditable = false; // Make the content non-editable
				}
			}
		});

		// If simple editor, remove some blocks
		if (simpleEditor) {
			editor.BlockManager.remove("map");
		}

		/* Remove the layer manager button */
		editor.Panels.removeButton("views", "open-layers");
		/* Remove devices resize */
		editor.Panels.removeButton("devices-c", "set-device-desktop");
		editor.Panels.removeButton("devices-c", "set-device-tablet");
		editor.Panels.removeButton("devices-c", "set-device-mobile");
		/* Remove fullscreen button (we control it) */
		editor.Panels.removeButton("options", "fullscreen");
		/* Remove "export" button (was showing source code) */
		editor.Panels.removeButton("options", "export-template");
		/* Remove preview button, useless */
		editor.Panels.removeButton("options", "preview");
		/* Remove import code button */
		editor.Panels.removeButton("options", "gjs-open-import-webpage");

		/* Get computed HTML (variables into handlebar) */
		const getComputedHTML = editor => {
			let html = editor.getHtml();
			let css = editor.getCss();

			// Process html

			// 1- get all span that are class="variable-component" from html
			let parser = new DOMParser();
			let doc = parser.parseFromString(html, "text/html");
			let variableComponents = doc.querySelectorAll("span.variable-component");

			// 2- tale all data-value attribute
			let variables = [];
			variableComponents.forEach(variableComponent => {
				let value = variableComponent.getAttribute("data-value");
				variables.push(value);
			});

			// 3- replace the span with the value of the data-value attribute in {{{ value }}}
			variableComponents.forEach(variableComponent => {
				let value = variableComponent.getAttribute("data-value");
				let textNode = doc.createTextNode(` {{{ ${value} }}} `);
				variableComponent.replaceWith(textNode);
			});

			// When we have more than one space, we need to replace it with a single space
			html = doc.documentElement.outerHTML;
			html = html.replace(/\s{2,}/g, " ");

			// Add the css to the html
			html = `${html} <style>${css}</style>`;
			html = juice(html);
			return html;
		};

		/* Add close button */
		editor.Panels.addButton("options", [
			{
				id: "close",
				// className: "fa fa-times icon-blank",
				className: "html-editor-medium-text",
				label: `<i class="fa fa-times" aria-hidden="true"></i>&nbsp;Fermer`,
				command: (editor1, sender) => {
					let html = getComputedHTML(editor1);
					onClose(html);
				},
				attributes: { title: t("EDITOR.close") }
			}
		]);

		/* Add save button */
		editor.Panels.addButton("options", [
			{
				id: "save",
				// className: "fa fa-floppy-o icon-blank",
				className: "html-editor-medium-text",
				label: `<i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;Enregistrer`,
				command: (editor1, sender) => {
					let html = getComputedHTML(editor1);
					onSave(html, editMode, answerItemId);
				},
				attributes: { title: t("EDITOR.save") }
			}
		]);

		/* Add custom Rich Text Editor action */
		editor.RichTextEditor.add("custom-vars", {
			icon: `<i class="fa fa-superscript" aria-hidden="true"></i>&nbsp;variable`,
			attributes: { title: t("EDITOR.variables") },
			result: rte => {
				let selected = rte.selection().getRangeAt(0);

				const span = document.createElement("span");
				span.className = "variable-component";
				span.innerHTML = t("EDITOR.variable");
				span.contentEditable = "false";

				selected.insertNode(span);
			}
		});

		editor.on("component:selected", () => {
			setVariablesDictionaryAnchor(null);
		});

		editorRef.current = editor;

		return () => {
			editor.destroy();
		};
	}, [open]);

	useEffect(() => {
		if (open && editorRef.current) {
			/* Init html */

			/**
			 * Convert variables to span (variable component)
			 */

			// 1- get all variables from html
			let variables = html.match(/{{{(.*?)}}}/g);
			if (variables) {
				// 2- replace all variables with the span
				variables.forEach(variable => {
					let value = variable.replace(/{{{(.*?)}}}/, "$1");

					// Get the dictionary item
					let dictionaryItem = lod_.get(
						variablesDictionary,
						value?.trim()?.replaceAll(".", ".items.")
					);

					// Replace the variable with the span, it will be automatically transformed to a variable component
					if (dictionaryItem) {
						html = html.replace(
							variable,
							`<span class="variable-component" data-value="${value}">${dictionaryItem?.label?.fr}</span>`
						);
					}
				});
			}

			// We need to reconvert the variable components parents to text components
			let parser = new DOMParser();
			let doc = parser.parseFromString(html, "text/html");
			let variableComponents = doc.querySelectorAll("span.variable-component");

			// For each parent add the data-gjs-type="text"
			variableComponents.forEach(variableComponent => {
				let parent = variableComponent.parentElement;
				parent.setAttribute("data-gjs-type", "text");
			});

			html = doc.documentElement.outerHTML;
			editorRef.current.setComponents(html);
		}
	}, [open]);

	return (
		<div className={`html-editor-container ${open ? "open" : "closed"}`}>
			<div id="gjs-container">
				<div id="gjs"></div>
			</div>
			{/* Variable dictionary */}
			<DictionaryMenu
				unstyled
				placement="right"
				dictionary={variablesDictionary}
				anchorEl={variablesDictionaryAnchor}
				handleInsertText={item => {
					let dictionaryItem = lod_.get(variablesDictionary, item?.replaceAll(".", ".items."));

					setVariablesDictionaryAnchor(null);
					if (!dictionaryItem) {
						return;
					}

					currentVariableBlock.addAttributes({ "data-value": item });
					currentVariableBlock.components(dictionaryItem.label.fr);
					setVariablesDictionaryAnchor(null);
				}}
				handleClose={() => setVariablesDictionaryAnchor(null)}
			/>
		</div>
	);
};

export default HTMLEditor;
