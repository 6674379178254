/* eslint-disable valid-typeof */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */

import MDBox from "components/Basics/MDBox";
import MDDatePicker from "components/Basics/MDDatePicker";
import { ButtonClearFilter } from "components/Custom/Filters/DateFilter";
import { useMaterialUIController } from "context";
import i18n from "i18n";
import { useEffect, useRef, useState } from "react";
import lod_, { set } from "lodash";
import { Add, Cancel, Close } from "@mui/icons-material";
import {
	Autocomplete,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	Menu,
	MenuItem,
	Popover,
	Select,
	TableCell,
	TableRow,
	Tooltip
} from "@mui/material";
import FormActions from "redux-react/actions/formAction";
import axios from "axios";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { useDispatch, useSelector } from "react-redux";

/**
 * Logic rows for the condition table
 * @returns
 */
const LogicManagerLine = ({
	logicCode,
	name,
	value,
	filter,
	methods,
	handleRemoveFilter,
	onChangeValue,
	dictionary
}) => {
	const dispatch = useDispatch();

	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const [selectedMethod, setSelectedMethod] = useState(logicCode || null);
	const [selectedValue, setSelectedValue] = useState(value || "");
	const [validFilter, setValidFilter] = useState(false);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const [viewInvalid, setViewInvalid] = useState(false);
	const [anchorElMenu, setAnchorElMenu] = useState(null);
	const [listOfApis, setListOfApis] = useState([]);

	const deleteItem = index => {
		let copySelecteds = lod_.cloneDeep(selectedValue);
		copySelecteds.splice(index, 1);
		setSelectedValue(copySelecteds);
	};
	useEffect(() => {
		const onSuccessApis = res => {
			setListOfApis(res?.apisList || []);
		};

		dispatch(FormActions.getListApis(assistantIDs, onSuccessApis));
	}, []);
	/**
	 * Get the correct input to select value for the filter
	 * @returns
	 */
	const getValues = () => {
		// Create empty list
		let list = [];
		// If filter have a list of values, use it
		if (filter.codes) {
			list = filter.codes;
		}
		// Get the complete method
		let completeMethod = methods.find(m => m.code === selectedMethod) || {};

		// If filter have a value, use it, its a mandatory value
		if (completeMethod.value !== undefined) {
			if (selectedValue !== completeMethod.value) {
				setSelectedValue(completeMethod.value);
			}
			return null;
		}
		// Get the filter type
		let type = filter.type;
		// If method have a type, use it, used to get different input
		if (completeMethod.type) {
			type = completeMethod.type;
		}
		// If filter have a whitelist, use it
		if (filter.whitelist) {
			let nList = {};
			for (let i = 0; i < filter.whitelist.length; i++) {
				nList[filter.whitelist[i]] = filter.whitelist[i];
			}
			list = nList;
		}

		switch (type) {
			case "array": {
				if (
					(list.length > 0 || (lod_.isObject(list) && !lod_.isEmpty(list))) &&
					!filter?.isDynamic
				) {
					if (!lod_.isArray(list)) {
						let newArray = [];
						let keys = Object.keys(list);
						for (let key of keys) {
							newArray.push({
								value: filter.type === "number" ? Number(key) : key,
								label: filter.type === "number" ? list[key].toString() : list[key]
							});
						}
						list = newArray;
					}

					if (filter?.isArray && filter?.whitelist && !lod_.isEmpty(filter?.whitelist)) {
						return (
							<Tooltip
								open={viewInvalid}
								title={i18n.t("FORMS.whitelistInvalidList")}
								placement="bottom"
								disableFocusListener
								disableHoverListener
								disableTouchListener
							>
								<Autocomplete
									value={lod_.isArray(value) ? value : []}
									onChange={(event, newValue) => {
										if (lod_.isEmpty(filter?.whitelist)) {
											setSelectedValue(newValue);
										} else {
											if (!lod_.isEmpty(newValue) && ["number", "timestamp"].includes(filter.type)) {
												const tableauDEntiers = newValue.map(chaine => parseInt(chaine, 10));
												const stringValuesWhite = tableauDEntiers.filter(val =>
													filter?.whitelist.includes(val)
												);

												if (stringValuesWhite.length === tableauDEntiers.length) {
													setSelectedValue(tableauDEntiers);
												} else {
													setViewInvalid(true);

													setTimeout(() => {
														setViewInvalid(false);
													}, 3000);
												}
											} else {
												const stringValuesWhite = newValue.filter(val =>
													filter?.whitelist.includes(val)
												);
												if (stringValuesWhite.length === newValue.length) {
													setSelectedValue(newValue);
												} else {
													setViewInvalid(true);

													setTimeout(() => {
														setViewInvalid(false);
													}, 3000);
												}
											}
										}
									}}
									multiple
									id="tags-filled"
									options={filter?.whitelist}
									freeSolo
									renderTags={(tags, getTagProps) => {
										return tags.map((option, index) => {
											return <Chip label={option} {...getTagProps({ index })} />;
										});
									}}
									renderInput={params => (
										<MDInput
											{...params}
											type={filter.type === "number" ? "number" : "text"}
											label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
										/>
									)}
								/>
							</Tooltip>
						);
					} else {
						return (
							<FormControl fullWidth>
								<InputLabel id="select-type">
									{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
								</InputLabel>
								<Select
									labelId="select-type"
									value={selectedValue}
									label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
									onChange={e => setSelectedValue(e.target.value)}
								>
									{list
										.sort((a, b) => a.label.localeCompare(b.label))
										.map((item, index) => {
											return (
												<MenuItem key={index} value={item.value}>
													{item.label}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						);
					}
				} else {
					if (filter?.whitelistDynamic && !lod_.isEmpty(filter?.whitelistDynamic)) {
						return (
							<>
								{lod_.isArray(selectedValue) &&
									!lod_.isEmpty(selectedValue) &&
									selectedValue.map((option, index) => {
										return (
											<Chip
												variant="outlined"
												style={{ backgroundColor: "#191919", color: "#ffffff" }}
												onDelete={() => deleteItem(index)}
												deleteIcon={<Cancel style={{ color: "#ffffff" }} />}
												label={option}
											/>
										);
									})}
								<IconButton
									onClick={e => {
										setAnchorElMenu(e.currentTarget);
									}}
								>
									<Add />
								</IconButton>
								<Popover
									anchorEl={anchorElMenu}
									open={Boolean(anchorElMenu)}
									onClose={() => setAnchorElMenu(null)}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center"
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center"
									}}
								>
									<MDBox style={{ width: "200px" }}>
										<LogicManagerWDList
											whitelistDynamic={filter?.whitelistDynamic}
											listOfApis={listOfApis}
											onChange={setSelectedValue}
											value={lod_.isArray(selectedValue) ? selectedValue : []}
											type={filter?.type}
										/>
									</MDBox>
								</Popover>
							</>
						);
					} else {
						return (
							<Tooltip
								open={viewInvalid}
								title={i18n.t("FORMS.whitelistInvalidList")}
								placement="bottom"
								disableFocusListener
								disableHoverListener
								disableTouchListener
							>
								<Autocomplete
									value={lod_.isArray(selectedValue) ? selectedValue : []}
									onChange={(event, newValue) => {
										if (!lod_.isEmpty(newValue) && filter.type === "number") {
											const tableauDEntiers = newValue.map(chaine => parseInt(chaine, 10));
											setSelectedValue(tableauDEntiers);
										} else {
											setSelectedValue(newValue);
										}
									}}
									multiple
									id="tags-filled"
									options={filter?.whitelist || []}
									freeSolo
									renderTags={(tags, getTagProps) => {
										return tags.map((option, index) => {
											return <Chip label={option} {...getTagProps({ index })} />;
										});
									}}
									renderInput={params => (
										<MDInput
											{...params}
											label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
											placeholder={i18n.t("SETTINGS.add")}
											type={filter.type === "number" ? "number" : "text"}
										/>
									)}
								/>
							</Tooltip>
						);
					}
				}
			}
			case "arrayList": {
				if (filter?.whitelistDynamic && !lod_.isEmpty(filter?.whitelistDynamic)) {
					return (
						<>
							{lod_.isArray(selectedValue) &&
								!lod_.isEmpty(selectedValue) &&
								selectedValue.map((option, index) => {
									return (
										<Chip
											variant="outlined"
											style={{ backgroundColor: "#191919", color: "#ffffff" }}
											onDelete={() => deleteItem(index)}
											deleteIcon={<Cancel style={{ color: "#ffffff" }} />}
											label={option}
										/>
									);
								})}
							<IconButton
								onClick={e => {
									setAnchorElMenu(e.currentTarget);
								}}
							>
								<Add />
							</IconButton>
							<Popover
								anchorEl={anchorElMenu}
								open={Boolean(anchorElMenu)}
								onClose={() => setAnchorElMenu(null)}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center"
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center"
								}}
							>
								<MDBox style={{ width: "200px" }}>
									<LogicManagerWDList
										whitelistDynamic={filter?.whitelistDynamic}
										listOfApis={listOfApis}
										onChange={setSelectedValue}
										value={lod_.isArray(selectedValue) ? selectedValue : []}
										type={filter?.type}
									/>
								</MDBox>
							</Popover>
						</>
					);
				} else {
					return (
						<Tooltip
							open={viewInvalid}
							title={i18n.t("FORMS.whitelistInvalidList")}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={lod_.isArray(selectedValue) ? selectedValue : []}
								onChange={(event, newValue) => {
									if (lod_.isEmpty(filter?.whitelist)) {
										let tableauFormat = newValue;
										if (["number", "timestamp"].includes(filter.type)) {
											tableauFormat = newValue.map(chaine => parseInt(chaine, 10));
										}
										setSelectedValue(tableauFormat);
									} else {
										if (!lod_.isEmpty(newValue) && ["number", "timestamp"].includes(filter.type)) {
											const tableauDEntiers = newValue.map(chaine => parseInt(chaine, 10));
											const stringValuesWhite = tableauDEntiers.filter(val =>
												filter?.whitelist.includes(val)
											);

											if (stringValuesWhite.length === tableauDEntiers.length) {
												setSelectedValue(tableauDEntiers);
											} else {
												setViewInvalid(true);

												setTimeout(() => {
													setViewInvalid(false);
												}, 3000);
											}
										} else {
											const stringValuesWhite = newValue.filter(val =>
												filter?.whitelist.includes(val)
											);
											if (stringValuesWhite.length === newValue.length) {
												setSelectedValue(newValue);
											} else {
												setViewInvalid(true);

												setTimeout(() => {
													setViewInvalid(false);
												}, 3000);
											}
										}
									}
								}}
								multiple
								id="tags-filled"
								options={filter?.whitelist || []}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => (
									<MDInput
										{...params}
										label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
										placeholder={i18n.t("SETTINGS.add")}
										type={["timestamp", "number"].includes(filter.type) ? "number" : "text"}
									/>
								)}
							/>
						</Tooltip>
					);
				}
			}
			case "boolean":
				return (
					<Checkbox
						checked={Boolean(selectedValue)}
						onClick={e => setSelectedValue(!Boolean(selectedValue))}
					/>
				);
			case "date":
			case "datetime":
				return (
					<MDInput
						type={filter.type}
						fullWidth
						value={selectedValue}
						label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
						onChange={e => setSelectedValue(e.target.value)}
					/>
				);
			case "timestamp":
				return (
					<MDBox
						className="datePickerContainer"
						style={{
							margin: 0
						}}
						mr={1}
					>
						<MDDatePicker
							options={{
								enableTime: true,
								noCalendar: true,
								dateFormat: "H : i",
								time_24hr: true,
								defaultDate: new Date(selectedValue * 1000)
							}}
							onChange={date => {
								let newDate = new Date(date);
								const timestamp = Math.floor(newDate.getTime() / 1000);

								setSelectedValue(timestamp);
							}}
							input={{
								className: "fullWidthDatePicker date_input_" + (darkMode ? "dark" : "light"),
								placeholder: `${filter.label.fr} (${i18n.t("FILTERS.date.start")})`
							}}
						/>
					</MDBox>
				);
			case "fieldSpe":
			case "field": {
				if (filter?.whitelistDynamic && !lod_.isEmpty(filter?.whitelistDynamic)) {
					return (
						<LogicManagerWD
							whitelistDynamic={filter?.whitelistDynamic}
							listOfApis={listOfApis}
							onChange={setSelectedValue}
							value={!lod_.isArray(selectedValue) ? selectedValue || "" : ""}
							type={filter?.type}
						/>
					);
				} else if (
					(list.length > 0 || (lod_.isObject(list) && !lod_.isEmpty(list))) &&
					!filter?.isDynamic
				) {
					if (!lod_.isArray(list)) {
						let newArray = [];
						let keys = Object.keys(list);
						for (let key of keys) {
							newArray.push({
								value: ["number", "timestamp"].includes(filter.type) ? Number(key) : key,
								label: ["number", "timestamp"].includes(filter.type)
									? list[key].toString()
									: list[key]
							});
						}
						list = newArray;
					}

					return (
						<FormControl fullWidth>
							<InputLabel id="select-type">
								{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
							</InputLabel>
							<Select
								labelId="select-type"
								value={selectedValue}
								label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
								onChange={e => setSelectedValue(e.target.value)}
							>
								{list
									.sort((a, b) => a.label.localeCompare(b.label))
									.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.label}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					);
				} else {
					return (
						<MDInput
							type={["timestamp"].includes(filter.type) ? "number" : filter.type}
							fullWidth
							value={selectedValue}
							label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
							onChange={e =>
								setSelectedValue(
									["number", "timestamp"].includes(filter.type)
										? Number(e.target.value)
										: e.target.value
								)
							}
						/>
					);
				}
			}
			default:
				if (list.length > 0 || (lod_.isObject(list) && !lod_.isEmpty(list))) {
					if (!lod_.isArray(list)) {
						let newArray = [];
						let keys = Object.keys(list);
						for (let key of keys) {
							newArray.push({
								value: filter.type === "number" ? Number(key) : key,
								label: filter.type === "number" ? list[key].toString() : list[key]
							});
						}
						list = newArray;
					}
					return (
						<FormControl fullWidth>
							<InputLabel id="select-type">
								{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
							</InputLabel>
							<Select
								labelId="select-type"
								value={selectedValue}
								label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
								onChange={e => setSelectedValue(e.target.value)}
							>
								{list
									.sort((a, b) => a.label.localeCompare(b.label))
									.map((item, index) => {
										return (
											<MenuItem key={index} value={item.value}>
												{item.label}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					);
				} else {
					if (filter?.whitelistDynamic && !lod_.isEmpty(filter?.whitelistDynamic)) {
						return (
							<>
								{lod_.isArray(selectedValue) &&
									!lod_.isEmpty(selectedValue) &&
									selectedValue.map((option, index) => {
										return (
											<Chip
												variant="outlined"
												style={{ backgroundColor: "#191919", color: "#ffffff" }}
												onDelete={() => deleteItem(index)}
												deleteIcon={<Cancel style={{ color: "#ffffff" }} />}
												label={option}
											/>
										);
									})}
								<IconButton
									onClick={e => {
										setAnchorElMenu(e.currentTarget);
									}}
								>
									<Add />
								</IconButton>
								<Popover
									anchorEl={anchorElMenu}
									open={Boolean(anchorElMenu)}
									onClose={() => setAnchorElMenu(null)}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center"
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center"
									}}
								>
									<MDBox style={{ width: "200px" }}>
										<LogicManagerWDList
											whitelistDynamic={filter?.whitelistDynamic}
											listOfApis={listOfApis}
											onChange={setSelectedValue}
											value={lod_.isArray(selectedValue) ? selectedValue : []}
											type={filter?.type}
										/>
									</MDBox>
								</Popover>
							</>
						);
					} else {
						return (
							<Autocomplete
								value={lod_.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									setSelectedValue(newValue);
								}}
								multiple
								id="tags-filled"
								options={[]}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => (
									<MDInput {...params} label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")} />
								)}
							/>
						);
					}
				}
		}
	};
	// Update row when filter change
	useEffect(() => {
		// Send onchange event only when filter is valid
		if (selectedMethod) {
			let completeMethod = methods.find(m => m.code === selectedMethod);

			let updatedValue = {
				path: filter.fullPath,
				name,
				method: completeMethod?.operator,
				logicCode: completeMethod?.code,
				value: selectedValue
			};

			onChangeValue(updatedValue);
		}
		setValidFilter(selectedValue);
	}, [selectedMethod, selectedValue]);

	useEffect(() => {
		if (filter.type === "boolean") {
			setSelectedMethod("number_equal");
			setSelectedValue(value || false);
		} else {
			setSelectedMethod(logicCode || null);
			setSelectedValue(value || "");
		}

		// default values
		switch (filter.type) {
			case "timestamp":
				// set default value to 01:00
				setSelectedValue(1 * 60 * 60 + 0 * 60);
				break;
			default:
				break;
		}
	}, [filter]);

	const getPath = path => {
		if (!path) return filter?.label?.fr || "";

		const parts = path.split(".");
		const result = [];
		let current = "";

		for (let i = 0; i < parts.length; i++) {
			current += parts[i];
			result.push(current);
			current += ".items.";
		}

		let stringPath = "";

		for (let i = 0; i < result.length; i++) {
			stringPath += " " + lod_.get(dictionary, result[i] + ".label.fr") + " /" || "";
		}

		stringPath = stringPath.slice(0, -1);
		return stringPath;
	};

	return (
		<TableRow>
			<TableCell>{getPath(filter?.fullPath) || filter.label?.fr}</TableCell>
			{filter.type === "boolean" ? (
				<TableCell></TableCell>
			) : (
				<TableCell>
					<FormControl fullWidth>
						<InputLabel id="select-type">{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.method")}</InputLabel>
						<Select
							labelId="select-type"
							value={selectedMethod}
							label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.method")}
							onChange={e => {
								setSelectedMethod(e.target.value);
							}}
						>
							{methods.map((method, index) => {
								if (method.type === "title") {
									return (
										<MenuItem key={index} disabled style={{ opacity: 1 }}>
											<MDTypography variant="caption">{method.title}</MDTypography>
										</MenuItem>
									);
								}
								if (method.type === "divider") {
									return <Divider key={index} />;
								}
								return (
									<MenuItem key={index} value={method.code}>
										{i18n.t(method.label)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</TableCell>
			)}
			<TableCell>{getValues()}</TableCell>
			<TableCell>
				<Tooltip placement="top" title={i18n.t("SETTINGS.delete")} arrow>
					<IconButton
						onClick={() => {
							handleRemoveFilter(name);
						}}
					>
						<Icon>delete</Icon>
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};

const LogicManagerWD = ({ whitelistDynamic, value, onChange, type = "string", listOfApis = [] }) => {
	const [valueAuto, setValueAuto] = useState(value);
	const [optionAuto, setOptionAuto] = useState([]);
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const { user } = useSelector(state => state);
	async function loadSuggestions(searchText, searchFields, apiName, action, limit = 10) {
		let attribute = whitelistDynamic?.params?.attribute;

		let APIFind;
		let actionFind;
		if (apiName && action && !lod_.isEmpty(listOfApis)) {
			APIFind = listOfApis.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			return [];
		}

		let account = actionFind?.account ?? false;

		// Merge collections is an array of collections name, that will be requested in the same time
		// The result will be merged in the same array
		const mergeCollections = actionFind?.mergeCollections || [];

		let config = {
			method: actionFind?.method,
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${actionFind?.collection}/dynamic?search=${searchText}&page=1&limit=${limit}&account=${account}`,
			data: { filter: actionFind?.filter, searchFields, attribute, mergeCollections },
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			}
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});
		return result?.results || [];
	}

	const getOptionLabel = option => {
		let labelAttribute = option[whitelistDynamic?.params?.attribute] || "";
		let label = whitelistDynamic.params?.searchFields
			? " - " + whitelistDynamic.params?.searchFields.map(key => option[key]).join(" - ")
			: "";
		if (
			whitelistDynamic.params?.searchFields &&
			!lod_.isEmpty(whitelistDynamic.params?.searchFields)
		) {
			label = labelAttribute + label;
		} else {
			label = labelAttribute;
		}
		return label;
	};

	const onChangeDynamic = (event, newValue, option) => {
		if (option === "selectOption") {
			let valueItemWD =
				type === "number"
					? parseFloat(newValue[whitelistDynamic.params.attribute])
					: newValue[whitelistDynamic.params.attribute];
			setValueAuto(valueItemWD.toString());
			onChange(valueItemWD);
		} else if (option === "clear") {
			setValueAuto("");
			onChange("");
		}
	};

	const ValueChange = async valueC => {
		setValueAuto(valueC);

		let result = await loadSuggestions(
			valueC,
			whitelistDynamic?.params?.searchFields || [],
			whitelistDynamic?.apiName || "",
			whitelistDynamic?.action || ""
		);

		setOptionAuto(result);
	};

	const clearWD = () => {
		ValueChange("");
		onChange("");
	};

	useEffect(() => {
		if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			ValueChange(value || "");
		}
	}, []);

	return (
		<MDBox mt={2} style={{ width: "100%", display: "flex", marginTop: 0 }}>
			<MDBox style={{ width: "100%" }}>
				<Autocomplete
					id="auto"
					freeSolo
					disableClearable
					options={optionAuto}
					onChange={onChangeDynamic}
					getOptionLabel={getOptionLabel}
					inputValue={valueAuto || ""}
					renderInput={params => (
						<MDInput
							{...params}
							label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
							onChange={e => ValueChange(e.target.value)}
						/>
					)}
				/>
			</MDBox>
			{valueAuto && valueAuto !== "" && (
				<IconButton onClick={() => clearWD()} style={{ width: "8%" }}>
					<Close />
				</IconButton>
			)}
		</MDBox>
	);
};

const LogicManagerWDList = ({
	whitelistDynamic,
	value,
	onChange,
	type = "string",
	listOfApis = []
}) => {
	const [valueAuto, setValueAuto] = useState("");
	const [optionAuto, setOptionAuto] = useState([]);
	const [open, setOpen] = useState(true);
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const { user } = useSelector(state => state);

	async function loadSuggestions(
		searchText,
		searchFields,
		apiName,
		action,
		filter = false,
		limit = 10
	) {
		let attribute = whitelistDynamic?.params?.attribute;

		let APIFind;
		let actionFind;
		if (apiName && action && !lod_.isEmpty(listOfApis)) {
			APIFind = listOfApis.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			return [];
		}

		if (filter && actionFind?.filter) {
			actionFind.filter = { ...actionFind.filter, ...filter };
		}

		let account = actionFind?.account ?? false;

		// Merge collections is an array of collections name, that will be requested in the same time
		// The result will be merged in the same array
		const mergeCollections = actionFind?.mergeCollections || [];

		let config = {
			method: actionFind?.method,
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${actionFind?.collection}/dynamic?search=${searchText}&page=1&limit=${limit}&account=${account}`,
			data: { filter: actionFind?.filter, searchFields, attribute, mergeCollections },
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			}
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});
		return result?.results || [];
	}

	const getOptionLabel = option => {
		let labelAttribute = option[whitelistDynamic?.params?.attribute] || "";
		let label = whitelistDynamic.params?.searchFields
			? " - " + whitelistDynamic.params?.searchFields.map(key => option[key]).join(" - ")
			: "";
		label = labelAttribute + label;
		return label;
	};

	const ValueChange = async (valueC, filter) => {
		setValueAuto(valueC);

		let valueFilter = filter ? filter : value;

		let result = await loadSuggestions(
			valueC,
			whitelistDynamic?.params?.searchFields || [],
			whitelistDynamic?.apiName || "",
			whitelistDynamic?.action || "",
			{ [whitelistDynamic?.params?.attribute]: { $nin: valueFilter } }
		);

		setOptionAuto(result);
		setOpen(true);
	};

	const inputRef = useRef(null);

	const onChangeDynamic = (event, newValue, option) => {
		if (option === "selectOption") {
			let copySelecteds = lod_.cloneDeep(value);
			let valueItemWD =
				type === "number"
					? parseFloat(newValue[whitelistDynamic.params.attribute])
					: newValue[whitelistDynamic.params.attribute];

			if (!copySelecteds.includes(valueItemWD)) {
				copySelecteds.push(valueItemWD);
				onChange(copySelecteds);
				setOpen(false);
				ValueChange("", copySelecteds);
			}
		} else if (option === "clear") {
			setValueAuto("");
		}
	};

	useEffect(() => {
		if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			ValueChange("");

			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	}, []);

	return (
		<MDBox>
			<Autocomplete
				id="auto"
				freeSolo
				open={open}
				disableClearable
				style={{ backgroundColor: "white" }}
				options={optionAuto}
				onChange={onChangeDynamic}
				getOptionLabel={getOptionLabel}
				inputValue={valueAuto || ""}
				renderInput={params => (
					<MDInput
						{...params}
						inputRef={inputRef}
						label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
						onChange={e => ValueChange(e.target.value)}
					/>
				)}
			/>
		</MDBox>
	);
};

export default LogicManagerLine;
